import React from 'react';
import logo from './logo.svg';
import './App.less';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeaderMenu from './components/Header';
import Home from './views/Home';
import Owner from './components/Owner';
import Contact from './views/Contact';
import { Gallery } from './views/Gallery';
import { AboutUS } from './components/AboutUs';
import { RetiredDog } from './views/Retired';
import { PuppyCare } from './views/PuppyCare';
import { MatykFooter } from './views/Footer';
import { Breed } from './views/Breed';
import { Privacy } from './views/Privacy';
import Questionaire from './components/questionaire';
import { ConfigProvider } from 'antd';
import { ConfigProviderProps } from 'antd/lib/config-provider';



function App() {
  ConfigProvider.config({theme: {primaryColor:"green"}})
  return (

    <div>
      <ConfigProvider
       >

     
      <Router>
        <HeaderMenu />
        <Routes>
          <Route path="/privacy" element={<Privacy />} />
        </Routes>

        <Routes>


          <Route path='/home' element={<Home />} />

          <Route index element={<Home />} />
          <Route path='/owner' element={<Owner />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/gallery/:breed' element={<Gallery />} />
          <Route path='/retired/:dogname' element={<RetiredDog />} />
          <Route path='/puppycare/:breed' element={<PuppyCare />} />
          <Route path='/breed/:breed' element={<Breed />} />
          <Route path='/contact/questionaire' element={<Questionaire />} />
          <Route path="/aboutus" element={<AboutUS />} />


          {/* </Route> */}
        </Routes>


        <MatykFooter />
      </Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
