import { Collapse, Image } from 'antd'
import { useState } from 'react';

const sourcePath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/";
const vidPath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/sophie/puppies/videos/pupps.mp4";
const { Panel } = Collapse
export const LitterComponent = (props) => {
    const breed = props.breed;
    const puppies = []
    var puppImgPath = sourcePath
    var showVideo = false;
    if (breed === "golden") {
        puppImgPath = puppImgPath + "sophie/puppies/"
        showVideo = true;
        for (const x of Array(31).keys()) {

            puppies.push("sophie_pup" + x)
        }
    } else {
        puppImgPath = puppImgPath + "allie/puppies/"
        for (const x of Array(28).keys()) {
            puppies.push("ali_pup" + x)
        }
        console.log("german")
    }

    puppies.splice(0, 1)
    console.log(puppies)
    const style = {
        padding: "2px",
        "&:hover": {
            width: 40
        },
        borderRadius: "10px"
    }
    return (
        <div>
            <Collapse accordion bordered style={{ padding: 10, border: "1px solid green", width: "80%", margin: "0 auto" }}>
                <Panel header={"Our Puppies - "} key="1" style={{ width: "80%", margin: "0 auto" }}>

                    <Image.PreviewGroup >
                        {
                            puppies.map(imgName => (

                                <Image width={200} src={puppImgPath + imgName + ".jpg"} alt={imgName} style={style} />

                            ))
                        }

                    </Image.PreviewGroup>
                    {showVideo &&
                        <div style={{ margin: "auto", width: "50%", padding: 10 }}>
                            <video width={"100%"} poster="https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/sophie/puppies/videos/pups.png" controls id="vid" >
                                <source src={vidPath} type="video/mp4" />
                                your browser does not support video tag
                            </video>
                        </div>}
                </Panel>
            </Collapse>
        </div>
    )
}