import { InfoCircleOutlined } from "@ant-design/icons";
// import { ValidationError, useForm } from "@formspree/react";
import { Button, Cascader, DatePicker, Form, Input, InputNumber, Radio, Select, Switch, Tag, TreeSelect } from "antd";
import { useState } from "react";

type SizeType = Parameters<typeof Form>[0]['size'];
type PetGender = "Male" | "Female";
type PreferredGender = "Happy to welcome any" | "Male" | "Female"
const PetsInfo = ()=>{
  return <>
    <Form layout={"inline"}>
          <Form.Item label="Age">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Breed">
            <Input />
          </Form.Item>
          <Form.Item label="Gender">
            <Select>
              <Select.Option value="Male" >Male</Select.Option>
              <Select.Option value="Female" >Female</Select.Option>
            </Select>
          </Form.Item>

        </Form>
        </>
 
}
const Questionaire = () => {
  
    // const [state, handleSubmit] = useForm("moqoraka");
    // if (state.succeeded) {
    //     return <p>Thanks for joining!</p>;
    // }

    const [componentSize, setComponentSize] = useState<SizeType | 'default'>('large');
    

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };
    return <div>
<h1> Matyk Questionaire</h1>
<Form
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 24 }}
      layout="horizontal"
      initialValues={{ size: componentSize }}
      onValuesChange={onFormLayoutChange}
      size={componentSize as SizeType}
      style={{ maxWidth: 800 }}
    >
      <Form.Item label="Form Size" name="size">
        {/* <Radio.Group>
          <Radio.Button value="small">Small</Radio.Button>
          <Radio.Button value="default">Default</Radio.Button>
          <Radio.Button value="large">Large</Radio.Button>
        </Radio.Group> */}
      </Form.Item>
      <Form.Item label="Name">
        <Input />
      </Form.Item>
      <Form.Item label="Phone Number">
        <Input />
      </Form.Item>
      <Form.Item label="Location">
        <Input />
      </Form.Item>
      <Form.Item label="Email">
        <Input />
      </Form.Item>
      <Form.Item label="Current Pets Info">
        <PetsInfo />
        <PetsInfo />
        <PetsInfo />
      </Form.Item>
      <Form.Item label="Have you had Golden retriever before?" valuePropName="checked">
        <Switch title="yes"/>
      </Form.Item>
      <Form.Item label="Have you researched the breed and training methods?" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Do you have a preference for a male or female puppy?" valuePropName="checked">
        <Select>
          <Select.Option value="Male">Male</Select.Option>
          <Select.Option value="Female">Female</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Would you accept the opposite sex if preference unavailable" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Will your Golden be allowed inside the house?" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Is your property fully fenced?" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Do you live on acreage, unit or rented property?" valuePropName="checked">
        <Input />
      </Form.Item>
      <Form.Item label="How often do you plan to walk your dog?" valuePropName="checked">
        <Input />
      </Form.Item>
      <Form.Item label="On Average how often, how many hours each day will the dog be at home alone?" valuePropName="checked">
        <Switch />
      </Form.Item>
  

      <Form.Item label="Select">
        <Select>
          <Select.Option value="demo">Demo</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="TreeSelect">
        <TreeSelect
          treeData={[
            { title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] },
          ]}
        />
      </Form.Item>
      <Form.Item label="Cascader">
        <Cascader
          options={[
            {
              value: 'zhejiang',
              label: 'Zhejiang',
              children: [{ value: 'hangzhou', label: 'Hangzhou' }],
            },
          ]}
        />
      </Form.Item>
      <Form.Item label="DatePicker">
        <DatePicker />
      </Form.Item>
      <Form.Item label="InputNumber">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Have you had Golden retriever before in your life?" valuePropName="checked">
        <Switch title="yes"/>
      </Form.Item>
      <Form.Item label="Button">
        <Button>Button</Button>
      </Form.Item>
    </Form>
    </div>
}
export default Questionaire;