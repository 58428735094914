import { useParams } from "react-router-dom"
import { Retired } from "../components/Retired"


export const RetiredDog =()=>{
    const {dogname} = useParams()

    return (
        <div>
            <Retired breed={dogname} />
        </div>
    )
}