import { Button, Col, Row } from "antd";
import { useEffect, useMemo, useState } from "react"
import QRCode from 'qrcode';

import './ContactComponent.css'


const MobileRow = (props) => {

    return (<div>
        <Row>
            <Col span={24} style={{ backgroundColor: "black", color: "white" }}> <p>click on choice of your contact medium </p></Col>
        </Row>
        <Row>

            <Col span={8} style={{ padding: 8 }}><a href={props.phone} ><Button type="primary" size="large">Call </Button></a> </Col>
            <Col span={8} style={{ padding: 8 }}><a href={props.text} ><Button type="primary" size="large">Text </Button></a> </Col>
            <Col span={8} style={{ padding: 8 }}><a href={props.email} ><Button type="primary" size="large">Email </Button></a> </Col>
        </Row>
    </div>
    )
}

const DesktopRow = (props) => {

    return <Row>

        <Col span={24} style={{ backgroundColor: "green", color: "white", fontWeight: "bold" }}>Scan QR code using your phone's camera to contact </Col>
        <Col span={8} style={{ padding: 8 }}>
            <p className="label">Call</p>
            <img src={props.call} alt="call" height={300} width={300} />
        </Col>
        <Col span={8} style={{ padding: 8 }}>
            <p className="label">Text</p>
            <img src={props.sms} alt="call" height={300} width={300} />
        </Col>
        <Col span={8} style={{ padding: 8 }}>
            <p className="label">Email</p>
            <img src={props.emailQR} alt="call" height={300} width={300} />
        </Col>


    </Row>
}
export const ContactComponent = (props) => {
    const [callLink, setCallLink] = useState('');
    const [emailLink, setEmailLink] = useState('');
    const [textLink, setTextLink] = useState('');
    const [qrCodeLink, setQrCodeLink] = useState('')
    const [smsQR, setSmsQR] = useState('')
    const [emailQR, setEmailQR] = useState('')


    const [isMobile, setDevice] = useState(false);
    const [os, setOs] = useState('');
    const setLinks = () => {
        if (os === "android") {
            setTextLink("sms:" + props.number + "?body=Query regarding to " + props.breed);
            setCallLink("tel:" + props.phone)



        }
        else if (os === "iOS") {
            setCallLink("tel:" + props.phone)
            setTextLink("sms:" + props.number + ";body=Query regarding to " + props.breed);
        }
        setEmailLink("mailto:" + props.email + "?subject=Query For " + props.breed + "&body= We would like to know if you have any litters available " + props.breed + " puppies");

    }
    const detectOS = () => {
        var userAgent = navigator.userAgent || navigator.vendor;
        if (/ Android | webOS | BlackBerry | IEMobile | Opera Mini | iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setDevice(true);
            if (/windows phone/.test(userAgent)) {
                setOs("Windows")
            }
            else if (/android/.test(userAgent)) {
                setOs("android")
            }
            else if (/iPad|iPhone|iPod/.test(userAgent)) {
                setOs("iOS")
            }
            else {
                setOs("Unknown")
            }
        } else {
            setDevice(false)
        }

    }
    useEffect(() => {
        detectOS();
        setLinks();



        QRCode.toDataURL("tel:" + props.number, function (err, url) {
            setQrCodeLink(url)
        })

        QRCode.toDataURL("sms:" + props.number, function (err, url) {
            setSmsQR(url)
        })
        QRCode.toDataURL(emailLink, function (err, url) {
            setEmailQR(url)
        })

    })
    return (
        <div className="contacttab" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
            <Row>
                <Col span={24} style={{ backgroundColor: "gray" }}>Contact Person: {props.name}</Col>


            </Row>
            <Row>



                <Col span={6}> </Col>

            </Row>

            {!isMobile && <DesktopRow email={emailLink} emailQR={emailQR} call={qrCodeLink} sms={smsQR} />}
            {isMobile && <MobileRow phone={callLink} email={emailLink} text={textLink} />}
            <Row>
                <Col span={24}></Col>
                <Col span={24}></Col>
                <Col span={24}></Col>
                <Col span={24}></Col>
            </Row>
            <Row>
                <Col span={24} >Contact Details</Col>
                <Col span={24}> </Col>
                <Col span={24}>{props.name}</Col>
                <Col span={24}><a href={"mailto:" + props.email} >{props.email}</a></Col>
                <Col span={24}><a href={"tel:" + props.number}>{props.number}</a></Col>
            </Row>


        </div>
    )

}
