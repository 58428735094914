import { useParams } from "react-router-dom"
import { BreedC } from "../components/Breed"


export const Breed =()=>{
    const { breed }=useParams()
    console.log(breed)

    return(
        <BreedC breed={breed} />
    )
}