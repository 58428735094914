import { useState } from "react";
import React from "react";


import ContactInfo from "./ContactInfo";
import { Button, Card } from "antd";





const Owner = () => {
    const [clicked, setClick] = useState("content");
    const updateClick = () => {
        if (clicked === "content") {
            setClick("showcontents")

        } else {
            setClick("content")
        }


    }
    return (



        <div><center>
            <h2>We have been enjoying company of our dogs </h2>
        </center>
        </div>


    )
}

export default Owner