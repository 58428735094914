import { Link } from "react-router-dom"
import  Flip from 'react-reveal/Flip'
import Flash from 'react-reveal/Flash'
import { Button } from "antd"



export const Card=(props)=>{
    const Icon = props.icon
    const content = props.content
    const link = props.link
    const color = props.color
    const label = props.label
    const backgroundColor = props.backgroundColor ?? "white"
    const fontColor = props.fontColor ?? "black"
    const isPuppyAlert = props.puppyAlert ?? false;
    const external = props.external ?? false
    
    
const externalStyle={
    colour: "white",
    backgroundColor:"green",
    margin: "1em"
}
return (
    <div style={{margin:20}} >
        
    <div style={{width:"80%", border:"0.5px solid green",
    backgroundColor: {backgroundColor}, color: {fontColor}, borderRadius:12, margin:"0 auto",boxShadow:"5px 4px 5px gray", padding:8}}>
    <Flip top mirror>
     <Icon style={{fontSize:"100px", color:color, width:"5%", margin:"0 auto", flex:"right"}} />
     </Flip>
     {external ? <div style={{margin:"auto", width:"80%"}}><Button style={externalStyle} type="default"> <a href={link} target="_blank" rel="noreferrer">DogzOnline</a></Button>
     <Button style={externalStyle} type="default"> <a href={link} target="_blank" rel="noreferrer">GRCV</a></Button>
      </div>:
     <Link to={link} style={{float:"right", color:"purple"}}>{label} </Link>
     }
     
     <h5 style={{width:"80%", margin:"0 auto"}}>{content}</h5>
     {isPuppyAlert &&  <Flash count={10} wait={2} style={{offsetY:"-12px"}}>
           <Link to={link} style={{float:"right", color:"purple"}}>We have a litter of Golden Retriever Puppies available!!! </Link>
        </Flash>}
    
    </div>
   
    </div>

)
}