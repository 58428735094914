import { Collapse, Image, Row } from "antd";

const { Panel } = Collapse
const golden_stud = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/sophie/stud/";
const german_stud = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/allie/stud/";
const stud_names=["stud1", "stud2","stud3", "stud4"]
const poster = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/sophie/puppies/videos/harry-sophie.png"
const vidPath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/sophie/puppies/videos/Harry-Sophie.mp4"
export const Stud = (props)=>{
    var stud_location =""
    if (props.stud ==="Harry"){
        stud_location=golden_stud
    } else{
        stud_location=german_stud
    }
  

    return (
       <Collapse accordion bordered style={{padding:10, border:"1px solid green",width:"80%", margin:"0 auto"}}>
           <Panel header={"Sire - "+props.stud} key="1" style={{width:"80%", margin:"0 auto"}}>
               {stud_names.map(stud=>(
                   <Image src={stud_location+stud+".jpg"} alt={props.stud} width={200} style={{padding:"10px"}}/>
               ))}
            

            {props.showVideo && <div style={{margin:"auto", width:"50%", padding:10}}>
            <video width={"100%"}   poster={poster} controls  id="vid" >
                <source src={vidPath } type="video/mp4" />
                your browser does not support video tag
            </video>
            </div>}
               </Panel>
           </Collapse>
    )
}