
import Slider from '../components/Slider';
import "./home.css"
import Zoom from 'react-reveal/Zoom'
import config from 'react-reveal/globals'



import { StarOutlined, CheckCircleOutlined, WarningOutlined, SmileOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Card } from '../components/Card';
import PuppyUpdate from '../components/PuppyUpdate';

config({ ssrFadeout: true })

export const Sophie = () => {
  const imgsrc = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/sophie/soph24.jpg"
  const isMobile = window.innerWidth <= 768
  return (
    <img src={imgsrc} style={{ borderRadius: 10, textAlign: "center" }} alt="Sophie"
      width={isMobile ? "100%" : "200px"} />
  )
}
export const SophiePuppy = () => {
  
  const imgsrc = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/thumnails/2023_sophie_puppies.jpg"

  return (
    <img style={{ borderRadius: 20, textAlign: "center" }} alt="Sophie" width={500}
      src={imgsrc} />
  )
}
export const Allie = () => {
  const imgsrc = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/allie/al3.jpg"

  return (
    <img src={imgsrc} style={{ borderRadius: 25, textAlign: "center" }} alt="Allie" width={200} />
  )
}





const Home = () => {


  return (

    <div className="App">
      <header className="App-header">


        <h2>Welcome to Matyk Kennels</h2>
        <div style={{margin: "20px"}}>
        {/* <PuppyUpdate /> */}
        </div>
       
        {/* <Card content=" "
          fontColor="blue" backgroundColor={"black"} link="contact"
          puppyAlert = {true}
        
          icon={SophiePuppy} style={{ backgroundColor: "black", color: "green" }} /> */}
       

        <center><Slider /></center>
        <h1>h</h1>
      </header>

      <Zoom right duration={1500} delay={800}>
        <Card icon={Sophie} content="Check our DogzOnline  and GRCV Profile"  color="blue" 
        external = {true}
        link="https://www.dogzonline.com.au/breeds/member.asp?name=tjgrayling" label="DogzOnline" />
        <Card icon={Sophie} content="Wanting to know about our Golden Retriever? " link="/gallery/golden" color="blue" label="Meet Sophie" />


        <Card icon={Allie} content="Wanting to know about our German Shepherd? " link="/gallery/german" color="green" label="Meet Alli" />


        <Card icon={ScheduleOutlined} content="Check with us if we have any litter available" link="/contact" color="red" label="Check in with us" />



        <Card icon={SmileOutlined} content="Learn how we started being involved growing healthy puppies" link="/aboutus" color="purple" label="Matyk Info" />

        <Card icon={CheckCircleOutlined} content="Get in touch to ask away any queries you may have" link="/contact" color={"lightblue"} label="Get in Touch" />

      </Zoom>

      <h1>....</h1>
      <div>

      </div>
    </div>
  )
}
export default Home