import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Slider.css'
const imgPath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/slide/";
const slides =["slide6","slide5","slide4","slide3","slide2","slide1","slide7","slide10", "slide11"]

const Slider = (props) =>{

    return (
        <div >
            <Carousel  autoPlay={true} autoFocus={true} infiniteLoop={true}  centerSlidePercentage={50} dynamicHeight width={"80%"}>
           
           {slides.map(slide=>(
               <div>
                   <img src={imgPath+slide+".jpg"} alt="Matyk" />
            </div>
           ))}
                
            </Carousel>
        </div>
    )
}

export default Slider