import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
// import Flash from "react-reveal/Flash"
const Flash = require("react-reveal/Flash")
const PuppyUpdate = () => {
    const [isMobile, setMobile] = useState(true);
    useEffect(()=>{
        setMobile(window.innerWidth <= 768)
    },[])
    const containerStyle: React.CSSProperties = {
        position: "relative",
        // maxWidth: "800px",
        margin: "0 auto",
        verticalAlign: "middle",
        width:  isMobile ? "80%" : "500px",


    };
    const labelStyle: React.CSSProperties = {
        position: "absolute",
        bottom: 0,
        background: "rgba(0,0,0,0.5)",
        color: "white",
        padding: "20px",
        textAlign: "center",
        width: "100%"
    }
    const imgStyle: React.CSSProperties = { verticalAlign: "middle", width: "100%", height: "auto" }
    const puppyImg = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/thumnails/2023_sophie_puppies.jpg"


    return (<div style={containerStyle}>
        <img src={puppyImg} alt="img" style={imgStyle} />

        <div style={labelStyle}>
        We have
            <Flash forever >
           
                <h3 style={{color:"green"}}>
              Golden puppies availabe now!!!
                </h3>
            </Flash>
            <Link to={"contact"}>contact us for info</Link>
        </div>


    </div>)
}
export default PuppyUpdate