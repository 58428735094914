

const golden = "Golden Retrievers"
const german = "German Shepherds"
export const BreedC = (props) => {
    const breed = props.breed
    var history = ''
    if (breed === "golden") {
        history = golden
    } else {
        history = german
    }

    return (
        <p>{history}</p>
    )
}