import { Col, Divider, Image, Layout, Row } from "antd"
import { useEffect } from "react"
import Slide from 'react-reveal/Slide'
const { Content } = Layout
const thumnailLink = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/thumnails/"
export const AboutUS = () => {
    useEffect(() => {
       
        window.scrollTo(0,0)
    })
    return (
        <div style={{ padding: "20px", margin: 10, border: "1px solid gray", borderRadius: 10 }}>

            <p>
                <Slide right duration={1500} delay={400}>
                    <Row style={{ padding: 10 }}>
                        <Col span={18} >
                            <Content style={{ padding: 24, margin: 0, minHeight: 200, border: "0.2px dotted gray", borderRadius: 10 }} > Matyk is a brother and sister team dedicated to the very selective breeding of Pedigree German Shepherds and Golden Retrievers.  We  have a 6 year old Speciality breed German Shepherd (long stock coat) bitch (retired) and a 4.5 year old Golden Retriever bitch who we have started a breeding schedule with last year.  Unfortunately, due to covid 19 restrictions, we have been unable to show these girls.
                            </Content>  </Col>
                        <Col span={6}><Divider orientation="right"><Image src={thumnailLink + "thumnail1.jpg"} style={{ borderRadius: 10 }} preview={false} /></Divider> </Col>
                    </Row>
                </Slide>
                <Slide left duration={1500} delay={600}>

                    <Row style={{ padding: 10 }}>
                        <Col span={6}><Divider orientation="left"><Image src={thumnailLink + "thumnail2.jpg"} style={{ borderRadius: 10 }} preview={false} /></Divider> </Col>
                        <Col span={18}>
                            <Content style={{ padding: 24, margin: 0, minHeight: 200, border: "0.2px dotted gray", borderRadius: 10 }} >We have had an affinity with dogs since early childhood and have owned and trained German Shepherds for over 20 years.  The personality traits of the German Shepherds have always been of utmost importance to us, as our dogs have been qualified therapy dogs and are frequently taken to visit people with disabilities in their homes as a form of pet therapy.  They have also been trained in dog obedience through German Shephard Club Victoria as well as local clubs. </Content></Col>
                    </Row>
                </Slide>
                <Slide right duration={1500} delay={700}>
                    <Row style={{ padding: 10 }}>
                        <Col span={18}> <Content style={{ padding: 24, margin: 0, minHeight: 200, border: "0.2px dotted gray", borderRadius: 10 }} >In 2007 we purchased the first German Shephard puppy with both the desired personality traits as well as good confirmation and hip/elbow scores.  Our previous girls had the right personality but failed the strict health requirement we desire to continue the bloodline. In 2012 we welcomed our first litter of puppies and have continue to improve the quality of the breed, with selective breeding, ever since. </Content></Col>
                        <Col span={6}><Divider orientation="right"><Image src={thumnailLink + "thumnail3.jpg"} style={{ borderRadius: 10 }} preview={false}/></Divider></Col>
                    </Row>
                </Slide>



                <Slide left duration={1500} delay={900}>
                    <Row style={{ padding: 10 }}>
                        <Col span={6}><Divider ><Image src={thumnailLink + "thumnail4.jpg"} style={{ borderRadius: 10 }} preview={false}/></Divider></Col>
                        <Col span={18}>
                            <Content style={{ padding: 24, margin: 0, minHeight: 200, border: "0.2px dotted gray", borderRadius: 10 }} >
                                In 2019 we decided to expand our kennel to include Golden Retrievers. We had always had a love for Golden Retrievers due to their loving and loyal nature and decided it was the right time to include them into our family.  
                            </Content>
                        </Col>
                    </Row>
                </Slide>
                {/* <Slide right duration={1500} delay={900}>
                    <Row style={{ padding: 10 }}>
                        
                        <Col span={18}>
                            <Content style={{ padding: 24, margin: 0, minHeight: 200, border: "0.2px dotted gray", borderRadius: 10, fontWeight: 'bold' }} >
                               Currently a litter of Golden Retrievers.
                               They will be ready for their new loving homes. 
                            </Content>
                        </Col>
                        <Col span={6}><Divider orientation="right"><Image src={thumnailLink + "thumnail3.jpg"} style={{ borderRadius: 10 }} preview={false}/></Divider></Col>
                    </Row>
                </Slide> */}



            </p>
        </div>
    )
}