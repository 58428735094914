export const Privacy = () => {
    return (
        <div className="outer-box" >
            

            <div className="contact-details" >
                <h1>Privacy</h1>
                <h4> No data uploaded to internet</h4>
                <p>Your data remains local on your device. 
                    We do not collect any personal information to any of our or third party websites.
                    Your data remains local on your device. 
                </p>
            </div>



        </div>

    )
} 