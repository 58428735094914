import { Collapse, Image } from 'antd'

const imgPath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/";
const golden = ["soph1", "soph2", "soph3", "soph4", "soph5", "soph6", "soph7","soph8", "soph9", "soph10", "soph11", "soph12", "soph13", "soph14", "soph15", "soph16", "soph17",  "soph19","soph21", "soph22", "soph23", "soph24", "soph25", "soph26", "soph27", "soph28", "soph29", "soph30"];
const german = ["al1", "al2", "al3", "al4", "al5", "al6", "al7", "al8","al9","al10","al11","al12","al13","al14","al15","al16","al17","al19","al20","al21","al22","al23","al24","al25","al26", "al27","al28"];
const marlee = ["marllee1", "marllee2", "marllee3"]
const poster = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/sophie/puppies/videos/sophie.png"
const vidPath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/sophie/puppies/videos/sophie-web.mp4"


const { Panel }= Collapse
export const GalleryC = (props) => {

    const breed = props.breed
    var petName = ""
    
    var imageNames = [];
    var dogName=""
    var showVideo=false

    console.log("breeed"+breed)
    switch (breed) {
        case "golden":
            imageNames = golden;
            petName = "sophie/";
            dogName="Sophie"
            showVideo = true
            
            break;
        case "german":
            imageNames = german;
            petName = "allie/";
            showVideo= false
            
            dogName="Alli"
            break;
        case "molden":
            imageNames = marlee;
            petName = "retired/";
            break;
        default:
            break;



    }
    const style = {
        padding: "2px",
        "&:hover": {
            width: 40
        },
        borderRadius:"10px"
    }

    return (
        <Collapse accordion bordered style={{padding:10, border:"1px solid green",width:"80%", margin:"0 auto"}}>
        <Panel header={"Current Dam - "+dogName} key="1" style={{width:"80%", margin:"0 auto"}}>
          
            <Image.PreviewGroup >
                {
                    imageNames.map(imgName => (
                      
                        <Image width={200} src={imgPath + petName + imgName + ".jpg"} alt={imgName} style={style} />
                        
                    ))
                }
             

            </Image.PreviewGroup>
            {showVideo === true && <div style={{margin:"auto", width:"50%", padding:10}}>
            <video width={"100%"}   poster={poster} controls  id="vid" >
                <source src={vidPath } type="video/mp4" />
                your browser does not support video tag
            </video>
            </div>}
        
      
        </Panel>
        </Collapse>
    )
}