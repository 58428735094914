import { useEffect } from "react";
import { useState } from "react";
import {  Tabs } from "antd";
import Rotate from 'react-reveal/Rotate'
import './contact.css'

import '../components/Owner.css';
import { ContactComponent } from "../components/ContactComponent";

const { TabPane } = Tabs
const imgPath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/reduced_swim.mp4";
const Contact = () => {
    const [clicked, setClick] = useState("content");
   
    useEffect(() => {
        document.getElementById("vid").play();
        window.scrollTo(0,0)
    })

    return (
        <div><center>

            <div className="contact-container" id="contact-container">
               <div >
               <video className="contact-video" autoPlay loop muted playsInline  id="vid" >
                <source src={imgPath } type="video/mp4" />
                your browser does not support video tag
            </video>
               </div>

           

            <div className="contact-tabs">

                
                <Tabs defaultActiveKey="1" onChange={"callback"} style={{ backgroundColor: "transparent", color: "white", fontWeight: "bold" }} >


                    <TabPane tab="Golden Retriever" key="1" style={{ backgroundColor: "transparent"}}>
                        <Rotate bottom left>
                            <ContactComponent name="Michael" number="+61411378859" email="mg80@live.com.au" breed="Golden Retriever" />
                        </Rotate>
                    </TabPane>



                    <TabPane tab="German Shepherd" key="2">
                        <Rotate bottom right>
                            <ContactComponent name="Tammy" number="+61421960527" email="tj79@live.com.au" breed="German Shepherd" />
                        </Rotate>
                    </TabPane>



                </Tabs>
            </div>



            </div>

           

        </center>
        </div>
    )
}

export default Contact