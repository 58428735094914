import { Col, Row } from "antd"
import { useParams } from "react-router-dom"
const imagePath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/thumnails/"
export const PuppyCare = ()=>{
    const { breed }= useParams()
    var thumnail = ""
    if(breed ==="golden"){
        thumnail="thumnail3.jpg"

    } else {
        thumnail="thumnail1.jpg"
    }
    return(
        <div style={{margin:10}}>
             
        <div style={{width:"50%", margin:"0 auto", border:"1px dashed black", borderRadius:20, padding:12}}>
       <Row>
           <Col span={8}>  <img src={imagePath+thumnail} width={200} alt={breed}/></Col>
           <Col span={16}> <h2>We provide Puppy pack with puppies where we endavour to provide you the best guideline how to look after puppies</h2>
               </Col>
       </Row>
      
           

           
        </div>
        </div>
    )
}