import { Col, Row } from "antd";

import { useParams } from "react-router-dom"
import { GalleryC } from "../components/GalleryComponent"
import { LitterComponent } from "../components/Litters";
import { Stud } from "../components/Parent";

const goldenQuote = ["Blessed is the person who has earned the love of a Golden Retriever", "I am a Golden Retriever but I never retrieve Gold", "The face of a Golden Retriever feels like home", "Money can buy you fine dog, but only love can make them wag their tail", "Happiness is a warm Golden Retriever"]
const germanQuote = ["Don't trust anyone who doesn't like a German Shepherd", "If my German Shepherd doesn't like you, neighter will I", "German Shepherds will love you more than themselves", "Money can buy you fine dog, but only love can make them wag their tail",]


const StudRow = (props) => {

    return (
        <Row style={{ padding: "10px" }}>
            <Col span={24}>
                <Stud stud={props.stud} showVideo={props.showVideo} />
            </Col>

        </Row>
    )
}
export const Gallery = () => {
    const { breed } = useParams();

    var stud = ""
    var quote = []
    var showVideo = false

    if (breed === "german") {
        stud = "Rolland"
        quote = germanQuote

    } else if (breed === "golden") {
        stud = "Harry"
        quote = goldenQuote
        showVideo = true
    }

    return <div>
        <h2 style={{ textAlign: "center", fontStyle: "italic", fontFamily: "Monova, cursive" }}><q> {quote[Math.floor(Math.random() * quote.length)]} </q></h2>
        <GalleryC breed={breed} />
        <StudRow stud={stud} showVideo={showVideo} />



        <LitterComponent breed={breed}/>





    </div>

}
