import { Image } from "antd";
import { useEffect, useRef } from "react";

const imgPath = "https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/retired/"
const marlee = ["marllee1", "marllee2", "marllee3"]
const mckenzie = ["Mckenzie"]
const tyler = ["tyler1", "tyler2", "tyler3"]

export const Retired = (props) => {
    const marlleeRef = useRef(null)
    const tylerRef = useRef(null)
    const mckenzieRef = useRef(null)


    const style = { fontFamily: "Cursive", fontWeight: "bold", marginTop: 50, lineHeight: "0.1em", textAlign: "center" }
    const spanStyle = { background: "#fff", padding: "0 10px" }
    var retired_names = []
    if (props.breed === "marllee") {
        retired_names = marlee;
    }
    const boxStyle = { border: "1px solid blue", borderRadius: 10, padding: "30px", margin: 5, textAlign: "center" }
    
    const scrollToSection =()=>{
     
        
        switch (props.breed) {
            case "marllee":
                marlleeRef.current.scrollIntoView()
                break;
            case "tyler":
                tylerRef.current.scrollIntoView()
                break;
            case "mckenzie":
                mckenzieRef.current.scrollIntoView()
                break
            default:
                break

        }

        
    }
    useEffect(() => {
        scrollToSection()

    })
    return (
        <div>
            <h2>Our previous German Shepherds</h2>


            <h4 style={style}><span style={spanStyle}>Marllee</span> </h4>

            <div style={boxStyle} ref={marlleeRef}>

                {retired_names.map((dog) => (
                    <Image width={200} src={imgPath + dog + ".jpg"} style={{ padding: "5px", borderRadius: "15px" }} />
                ))}

            </div>
            <h4 style={style}> <span style={spanStyle}>Tyler</span></h4>
            <div style={boxStyle} ref={tylerRef}>

                {tyler.map((dog) => (
                    <Image width={200} src={imgPath + "Tyler/" + dog + ".jpg"} style={{ padding: "5px", borderRadius: "15px" }} />
                ))}
            </div>
            <h4 style={style}> <span style={spanStyle}>McKenzie</span></h4>
            <div style={boxStyle} ref={mckenzieRef}>
                {mckenzie.map((dog) => (
                    <Image width={200} src={imgPath + "McKenzie/" + dog + ".jpg"} style={{ padding: "5px", borderRadius: "15px" }} />
                ))}
            </div>
        </div>


    )
}