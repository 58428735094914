import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';



import './header.css'
const imgPath = process.env.PUBLIC_URL + "/images/";
const { SubMenu } = Menu;
const HeaderMenu = () => {
    const drawerClick = () => {
        var x = document.getElementById("menus");
        if (x.className === "menus") {
            x.className += "-responsive"
        } else {
            x.className = "menus"
        }
    }

    const style = {
        margin: 5,
        fontSize: 22, color: 'white', padding: 5
    }
    const german = (
        <Menu >
            <Menu.Item>
                <Link to="/puppycare/german"> Puppy Care</Link>
            </Menu.Item>

            <Menu.Item>
                <Link to='/breed/german'> About Breed</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/gallery/german" > Gallery</Link>
            </Menu.Item>
        </Menu>
    )
    const golden = (
        <Menu style={{ padding: 5 }}>
            <Menu.Item>
                <Link to="/puppycare/golden"> Puppy Care</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to='/breed/golden'> About Breed</Link>
            </Menu.Item>

            <Menu.Item>
                <Link to="/gallery/golden"> Gallery</Link>
            </Menu.Item>
        </Menu>
    )
    const gallery = (
        <Menu style={{ padding: 5 }}>
            <Menu.Item>
                <Link to="/gallery/german" > German Shepherd</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/gallery/golden"> Golden Retriever</Link>
            </Menu.Item>
            <Menu.ItemGroup title="Previous German Shepherds" />
            <SubMenu title="Retired German Shepherds">
                <Menu.Item>
                    <Link to="/retired/marllee" >Marllee </Link></Menu.Item>
                <Menu.Item><Link to="/retired/tyler">Tyler</Link></Menu.Item>
                <Menu.Item><Link to="/retired/mckenzie">McKenzie</Link></Menu.Item>
            </SubMenu>

        </Menu>
    )

    return (
        <div className="headers">
            <Link to='/' ><img src={"https://matyk-kennel.s3.ap-southeast-2.amazonaws.com/logo.png"} alt="Matyk Kennel" width={200} /></Link>
           
            <div className='menus' id='menus'>

                <ul>
                    <li>
                        <Button type='text'><Link to="/home" style={style} >Home</Link></Button>
                    </li>
                    <li>
                        <Dropdown overlay={gallery} style={style}>
                            <a target="_blank" rel="noopener noreferrer" className='ant-dropdown-link' onClick={e => e.preventDefault()} style={style}> Gallery<DownOutlined /> </a>
                        </Dropdown>
                    </li>
                    <li>

                    </li>
                    <li>
                        <Dropdown overlay={golden} style={style}>
                            <a className='ant-dropdown-link' onClick={e => e.preventDefault()} style={style}> Golden Retrievers<DownOutlined /> </a>
                        </Dropdown>
                    </li>
                    <li>
                        <Dropdown overlay={german} style={style}>
                            <a target="_blank" rel="noopener noreferrer" className='ant-dropdown-link' style={style}> German Shepherds <DownOutlined /> </a>
                        </Dropdown>
                    </li>
                    <li>
                        <Button type='text'><Link to="/contact" style={style}>Contact US</Link></Button>
                    </li>
                    <li>
                        <Button type='text'><Link to="/aboutus" style={style}>About US</Link></Button>
                    </li>
                </ul>









            </div>
          
            <div className='icon'>
                <MenuOutlined style={{ color: "white", fontSize: 30, marginTop: 20, marginRight: 15 }} onClick={drawerClick} />
            </div>

        </div>
    )
}

export default HeaderMenu
